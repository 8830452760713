import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";

import { fallbackImage } from "./fallbackImage";
import { getSiteMetaData } from "./siteMeta";

export default function useOpenGraph(initialSeo) {
  const pageContext = usePageContext();
  let contentTypeName = pageContext.contentType?.name;
  let openGraph = pageContext?.openGraph;
  let content = pageContext?.content;
  let description = pageContext?.description;
  let featuredImage = pageContext?.featuredImage;
  let uri = pageContext?.uri;
  const siteMeta = getSiteMetaData();
  const { processPageContent, stripHTML } = useHTMLProcessor();

  if (contentTypeName) {
    const processedContent = processPageContent({ content });

    if (!description) {
      if (processedContent.preamble) {
        description = processedContent.preamble;
      } else {
        description = stripHTML(content).slice(0, 300);
      }
    }
    if (description === "undefined") {
      description = null;
    }

    return {
      metaTitle: openGraph?.title || initialSeo.title,
      metaDescription:
        openGraph?.description || description || initialSeo.description,
      metaImage: openGraph?.image || featuredImage?.node || fallbackImage(),
      metaUrl: siteMeta.siteUrl + uri,
    };
  }

  return {
    metaTitle: initialSeo.title,
    metaDescription: initialSeo.description,
    metaImage: fallbackImage(),
    metaUrl: siteMeta.siteUrl,
  };
}
