import { useModularityModule } from "@municipio/gatsby-theme-basic/src/hooks";
import { getMainArchivePagePathFromPageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/contentType";

export default function useArchiveLink() {
  const { module } = useModularityModule();

  const archiveLinkLabel = module.modPostsDataSource.customArchiveLink?.url
    ? module.modPostsDataSource.customArchiveLink.title
    : module.modPostsDataSource?.postsDataPostType?.labels?.allItems;
  const archiveLinkUri = module.modPostsDataSource.customArchiveLink?.url
    ? module.modPostsDataSource.customArchiveLink.url
    : module.modPostsDataSource?.postsDataPostType &&
      getMainArchivePagePathFromPageContext({
        contentType: module.modPostsDataSource?.postsDataPostType,
      });

  const archiveLinkTarget = module.modPostsDataSource.customArchiveLink?.url
    ? module.modPostsDataSource.customArchiveLink.target
    : null;

  const showArchiveLink =
    (module.modPostsDataSource.postsDataSource == "input" &&
      module.modPostsDataSource.customArchiveLink?.url) ||
    (module.modPostsDataSource?.archiveLink &&
      module.modPostsDataSource.customArchiveLink?.url) ||
    (module.modPostsDataSource?.archiveLink &&
      module.modPostsDataSource?.postsDataPostType?.hasArchive &&
      archiveLinkUri &&
      archiveLinkLabel);

  return {
    archiveLinkLabel,
    archiveLinkUri,
    archiveLinkTarget,
    showArchiveLink,
  };
}
