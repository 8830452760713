import { css } from "@emotion/react";
import { treeMenuContext } from "@whitespace/components/src/contexts";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import TreeMenuItem from "./TreeMenuItem";

TreeMenuList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  parentPath: PropTypes.arrayOf(PropTypes.number),
  theme: PropTypes.string,
};

export default function TreeMenuList({
  items,
  parentPath = [],
  theme,
  ...restProps
}) {
  const { styles } = useContext(treeMenuContext);

  if (items.length === 0) {
    return null;
  }

  return (
    <ul
      css={
        theme &&
        css`
          --theme: var(--color-primary-tint-3);
        `
      }
      className={clsx(styles.list, parentPath.length > 0 && styles.sublist)}
      {...restProps}
    >
      {items.map((item, index) => {
        return (
          <TreeMenuItem path={[...parentPath, index]} key={index} item={item} />
        );
      })}
    </ul>
  );
}
