import { H } from "@jfrk/react-heading-levels";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./LandingPageHero.module.css";

LandingPageHero.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  preamble: PropTypes.string,
  landingPageHeroOptions: PropTypes.shape({
    logo: PropTypes.string,
    ctaTitle: PropTypes.string,
    ctaLink: PropTypes.string,
  }),
};

export default function LandingPageHero({
  title,
  preamble,
  landingPageHeroOptions,
  styles = defaultStyles,
}) {
  const { logo, ctaTitle, ctaLink } = landingPageHeroOptions;
  const ctaPresent = !!ctaTitle && !!ctaLink?.uri;
  return (
    <>
      {logo?.mediaItemUrl && (
        <div
          style={{
            position: "absolute",
            backgroundImage: `url(${logo.mediaItemUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "400px 400px",
            backgroundPosition: "center",
            opacity: "0.15",
            height: "400px",
            width: "100%",
            margin: "12px 0",
          }}
        />
      )}
      <div className={clsx(styles.hero, styles.wrapper)}>
        <div className={clsx(styles.inner)}>
          <H className={clsx(styles.title, styles.heroItem)}>{title}</H>
          <p className={clsx(styles.preamble, styles.heroItem)}>{preamble}</p>
          {ctaPresent && (
            <a
              className={clsx(styles.button, styles.heroItem)}
              href={ctaLink.uri}
            >
              {ctaTitle}
            </a>
          )}
        </div>
      </div>
      <div className={clsx(styles.border)} />
    </>
  );
}
