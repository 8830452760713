import ModuleController from "@municipio/gatsby-theme-basic/src/components/ModuleController";
import modularityAreaContext from "@municipio/gatsby-theme-basic/src/modularityAreaContext";
import modularityModuleContext from "@municipio/gatsby-theme-basic/src/modularityModuleContext";
import { parseColumnWidth } from "@municipio/gatsby-theme-basic/src/utils";
import React from "react";

import PageGridFullWidth from "../../../@whitespace/gatsby-theme-wordpress-basic/components/PageGridFullWidth";
import PageGridItem from "../../../@whitespace/gatsby-theme-wordpress-basic/components/PageGridItem";
import PageGridRow from "../../../@whitespace/gatsby-theme-wordpress-basic/components/PageGridRow";

function makeRows(modules) {
  let rows = [];
  let currentRow = { modules: [] };
  let currentRowColspan = 0;
  for (let module of modules) {
    const colspan = module.colspan || 12;
    if (colspan + currentRowColspan > 12) {
      rows.push(currentRow);
      currentRow = { modules: [] };
      currentRowColspan = 0;
    }
    currentRow.modules.push(module);
    currentRowColspan += colspan;
  }
  if (currentRow.modules.length) {
    rows.push(currentRow);
  }
  return rows;
}

export default function ModularityArea({
  area = {},
  defaultColspan = 8,
  ...restProps
}) {
  const { modules } = area;
  if (!modules?.length) {
    return null;
  }
  let moduleRows = makeRows(
    modules
      .filter(({ module, hidden }) => module && !hidden)
      .map(({ columnWidth, ...rest }) => ({
        colspan: parseColumnWidth(columnWidth),
        ...rest,
      })),
  );
  return (
    <modularityAreaContext.Provider value={area}>
      <PageGridFullWidth as="div" {...restProps}>
        {moduleRows.map(({ modules }, index) => {
          return (
            <PageGridRow key={index}>
              {modules.map(({ hidden, module, colspan, ...rest }, index) => {
                // const MaybePageGridGroup =
                //   parseColumnWidth(columnWidth) || area.defaultModuleColSpan
                //     ? PageGridGroup
                //     : React.Fragment;
                return (
                  <modularityModuleContext.Provider
                    value={{ hidden, module, colspan, ...rest }}
                    key={index}
                  >
                    <PageGridItem colspan={colspan || defaultColspan}>
                      <ModuleController module={module} />
                    </PageGridItem>
                  </modularityModuleContext.Provider>
                );
              })}
            </PageGridRow>
          );
        })}
      </PageGridFullWidth>
    </modularityAreaContext.Provider>
  );
}
