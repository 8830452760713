import { css } from "@emotion/react";
import Grid from "@municipio/gatsby-theme-basic/src/components/Grid";
import PostsModuleFilterForm from "@municipio/gatsby-theme-basic/src/components/modularity-modules/PostsModuleFilterForm";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import PostsModuleHeader from "@municipio/gatsby-theme-basic/src/components/PostsModuleHeader";
import { useSearch } from "@whitespace/gatsby-plugin-search/src/hooks";
import { kebabCase } from "lodash";
import React from "react";

export default function PostsModuleGridLayout({
  itemComponent: Item,
  title,
  module,
  normalizedItems,
  ...restProps
}) {
  const {
    modPostsDataDisplay: { theme },
  } = module;
  const { schema } = useSearch();

  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      components={{
        ModuleWrapperHeader: PostsModuleHeader,
      }}
      css={css({
        "--card-background": theme
          ? `var(--brand-color-${kebabCase(theme)})`
          : "white",
        "--card-hover-background": theme
          ? `var(--brand-color-${kebabCase(theme)})`
          : "white",
        "--card-color": theme
          ? `var(--brand-color-${kebabCase(theme)}-text)`
          : null,
        "--card-hover-color": theme
          ? `var(--brand-color-${kebabCase(theme)}-text)`
          : null,
        "--card-meta-color": theme
          ? `var(--brand-color-${kebabCase(theme)}-text)`
          : null,
        "--module-wrapper-title-rule-color": theme
          ? `var(--brand-color-${kebabCase(theme)})`
          : null,
        padding: theme ? "0 var(--spacing-md)" : null,
      })}
    >
      <PostsModuleFilterForm />
      <Grid autoFit={!schema}>
        {normalizedItems.map((item, index) => {
          return <Item key={index} item={{ theme, ...item }} />;
        })}
      </Grid>
    </ModuleWrapper>
  );
}
