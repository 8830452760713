/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import PropTypes from "prop-types";

Notice.propTypes = {
  type: PropTypes.oneOf(["danger", "warning", "info", "success"]),
  children: PropTypes.node,
};

export default function Notice({ children, type = "info", ...restProps }) {
  return (
    <div
      // eslint-disable-next-line react/no-unknown-property
      css={css`
        background: var(--color-${type});
        color: var(--color-${type}-foreground);
        padding: 1rem;
      `}
      {...restProps}
    >
      {children}
    </div>
  );
}
