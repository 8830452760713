/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Section } from "@jfrk/react-heading-levels";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";

import SEO from "../../../components/Seo";
import PageChildNavigation from "../components/PageChildNavigation";
import PageContent from "../components/PageContent";
import PageContentAreaModules from "../components/PageContentAreaModules";
import PageFeaturedImage from "../components/PageFeaturedImage";
import PageGrid from "../components/PageGrid";
import PageGridItem from "../components/PageGridItem";
import PageGridRow from "../components/PageGridRow";
import PageHeading from "../components/PageHeading";
import PagePreamble from "../components/PagePreamble";
import PageSiblingNavigation from "../components/PageSiblingNavigation";

export default function DefaultPageTemplate() {
  const { title, isFrontPage } = usePageContext();
  return (
    <article
      css={css`
        margin-top: var(--spacing-md);
        margin-bottom: var(--spacing-xxl);
      `}
    >
      <SEO title={title} isFrontPage={isFrontPage} />

      {/* Featured image */}
      <PageFeaturedImage />

      {/* Title */}
      <PageGrid
        css={css`
          padding: 0 var(--spacing-lg);
        `}
      >
        <PageGridRow>
          <PageGridItem>
            <PageHeading css={css``} />
          </PageGridItem>
        </PageGridRow>
      </PageGrid>

      {/* child nav, preamble, content, modules, meta, sibling nav */}
      <Section>
        <PageGrid
          css={css`
            padding: 0 var(--spacing-lg);
            margin-bottom: var(--spacing-lg);
          `}
        >
          <PageGridRow>
            <PageGridItem>
              <PageChildNavigation />
              <PagePreamble
                css={css`
                  margin-bottom: 2.5rem;
                `}
              />
              <PageContent />
            </PageGridItem>
          </PageGridRow>
        </PageGrid>
        <PageContentAreaModules
          defaultColspan={8}
          css={css`
            padding: 0 var(--spacing-lg);
          `}
        />
        {/* <footer className={styles.footer}>
          <PageMeta />
        </footer> */}
        <PageGrid
          css={css`
            padding: 0 var(--spacing-lg);
            margin-top: var(--spacing-lg);
            margin-bottom: var(--spacing-lg);
          `}
        >
          <PageGridRow>
            <PageGridItem>
              <PageSiblingNavigation />
            </PageGridItem>
          </PageGridRow>
        </PageGrid>
      </Section>
    </article>
  );
}
