import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import logo from "../images/Logo.svg";

import * as defaultStyles from "./Logo.module.css";

Logo.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function Logo({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  return (
    <span className={clsx(styles.component, className)} {...restProps}>
      <img src={logo} alt="" />
    </span>
  );
}
