import { useStaticQuery, graphql } from "gatsby";

export default function getFallbackImage() {
  return useStaticQuery(graphql`
    query GET_PAGES {
      file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "fallback.png" }
      ) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
}

export function fallbackImage() {
  const image = getFallbackImage();
  return {
    width: 1200,
    height: 627,
    src: image.site.siteMetadata.siteUrl + image.file.childImageSharp.fluid.src,
  };
}
