import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";

const PageGridItem = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && !["colspan"].includes(prop),
})`
  --page-grid-columns: ${(props) => props.colspan};
  box-sizing: border-box;
  flex-grow: var(--grid-columns);
  flex-shrink: var(--grid-columns);
  flex-basis: calc(
    var(--page-grid-columns) / 12 * (100% + var(--page-grid-gap)) -
      var(--page-grid-gap) - 0.1px
  );

  min-width: var(--page-grid-item-min-width);
  width: 100%;
  max-width: var(--page-grid-item-max-width);
`;

export default PageGridItem;
