import { css } from "@emotion/react";
import * as defaultStyles from "@municipio/gatsby-theme-basic/src/components/modularity-modules/MenuModule.module.css";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { Link, RoundIcon } from "@whitespace/components";
import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import { getPage } from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import clsx from "clsx";
import React from "react";

const iconBackgroundColor = (name) =>
  ({
    "electrical-trade": "#bc266c",
    "energy-system": "#00738a",
    heating: "#5d7d37",
    "energy-solutions": "#db621e",
  }[name]);

function defaultTransformMenuItem(menuItem, { pages }) {
  let { connectedNode, label, description, url, target, extra, ...rest } =
    menuItem;

  let icon = extra?.icon || rest?.icon;
  if (typeof icon === "string") {
    icon = { name: icon };
  }

  let { contentType: { node: { name: type = "custom" } = {} } = {}, id } =
    connectedNode?.node || {};

  let content = type === "page" ? getPage(pages, id) : {};

  return {
    type,
    url,
    target: connectedNode?.node?.id ? target : "_blank",
    ...content,
    label,
    description: description || (content && content.description),
    ...extra,
    ...rest,
    icon,
  };
}

export default function MenuModule({
  className,
  module = {},
  styles = defaultStyles,
  title,
  transformMenuItem = defaultTransformMenuItem,
  ...restProps
}) {
  const pages = usePages();
  const { menu } = module;

  // const theme = useTheme();

  let menuItems = menu?.node?.menuItems?.nodes?.map((node) =>
    transformMenuItem(node, { pages }),
  );

  if (!menuItems?.length) {
    return null;
  }

  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, className)}
    >
      <div className={styles.wrapper}>
        <>
          {menuItems && menuItems.length > 0 && (
            <nav className={styles.nav} aria-label="Genvägar">
              <ul className={styles.list}>
                {menuItems.map((item, index) => (
                  <li className={styles.item} key={index}>
                    <Link className={styles.link} to={item.url}>
                      {item?.icon && (
                        <RoundIcon
                          {...item.icon}
                          className={styles.icon}
                          iconProps={{ size: "2.5rem" }}
                          css={css({
                            "--round-icon-background-color":
                              iconBackgroundColor(item.icon.name),
                            "--round-icon-size": "4rem",
                            border: "5px solid white",
                            outline: `5px solid ${iconBackgroundColor(
                              item.icon.name,
                            )}`,
                          })}
                        />
                      )}
                      <span className={styles.label}>{item.label}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </>
      </div>
    </ModuleWrapper>
  );
}
