import { css } from "@emotion/react";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import PostsModuleHeader from "@municipio/gatsby-theme-basic/src/components/PostsModuleHeader";
import { Link, RoundIcon } from "@whitespace/components";
import clsx from "clsx";
import { kebabCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";

//import Grid from "../../../Grid";

//import PostsModuleFilterForm from "@municipio/gatsby-theme-basic/src/components/modularity-modules/PostsModuleFilterForm";
import * as defaultStyles from "./HorizontalPostsModule.module.css";

HorizontalPostsModule.propTypes = {
  className: PropTypes.string,
  itemComponent: PropTypes.elementType.isRequired,
  module: PropTypes.shape({
    modPostsDataDisplay: PropTypes.shape({
      postsFields: PropTypes.arrayOf(PropTypes.string),
      theme: PropTypes.string,
    }),
    modPostsDataSource: {
      archiveLink: PropTypes.bool,
      postsDataPostType: PropTypes.shape({
        hasArchive: PropTypes.bool,
        uri: PropTypes.string,
        labels: PropTypes.shape({
          allItems: PropTypes.string,
          menuName: PropTypes.string,
        }),
      }),
    },
  }).isRequired,
  normalizedItems: PropTypes.arrayOf(PropTypes.object),
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  components: PropTypes.objectOf(PropTypes.elementType),
};

export default function HorizontalPostsModule({
  styles = defaultStyles,
  className,
  title,
  module,
  normalizedItems,
  ...restProps
}) {
  const {
    modPostsDataDisplay: { theme },
  } = module;

  //const { schema } = useSearch();

  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, theme, className)}
      css={css({
        "--shortcut-link-background": theme
          ? `var(--brand-color-${kebabCase(theme)});`
          : null,
        "--shortcut-link-hover-background": theme
          ? `var(--brand-color-${kebabCase(theme)});`
          : null,
        "--shortcut-link-color": theme
          ? `var(--brand-color-${kebabCase(theme)}-text);`
          : `var(--module-wrapper-header-link-color)`,
        "--shortcut-link-hover-color": theme
          ? `var(--brand-color-${kebabCase(theme)}-text);`
          : null,
        "--shortcut-link-meta-color": theme
          ? `var(--brand-color-${kebabCase(theme)}-text);`
          : "",
        "--shortcut-link-text-text-color": `var(--color-light);`,
      })}
      components={{
        ModuleWrapperHeader: PostsModuleHeader,
      }}
    >
      <div className={clsx(styles.list)}>
        {normalizedItems.map((item, index) => {
          return (
            <div key={index} className={clsx(styles.item)}>
              <Link
                className={styles.link}
                to={item.uri}
                //            target={archiveLinkTarget}
              >
                {item.title}
              </Link>
            </div>
          );
        })}
      </div>
    </ModuleWrapper>
  );
}
