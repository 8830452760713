/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import TextContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/TextContent";
// import { Fragment } from "react";

import usePageContent from "../hooks/usePageContent";

export default function PagePreamble({ ...restProps }) {
  const { preamble } = usePageContent();

  if (!preamble) {
    return null;
  }

  return (
    <TextContent
      {...restProps}
      css={css`
        --typography-font-size: var(--typography-preamble-font-size);
        --typography-font-weight: var(--typography-preamble-font-weight);
        --typography-line-height: var(--typography-preamble-line-height);
        opacity: 0.8;
      `}
    >
      {preamble}
    </TextContent>
  );
}
