import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import clsx from "clsx";
import { camelCase, startCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";

LayoutComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
};

export default function LayoutComponent({
  type = "full",
  children,
  className,
  ...restProps
}) {
  return (
    <div
      className={clsx(
        layout.component,
        layout[camelCase("componentWidth" + startCase(type))],
        className,
      )}
      {...restProps}
    >
      {children}
    </div>
  );
}
