// extracted by mini-css-extract-plugin
export var border = "LandingPageHero-module--border--7pSXw";
export var button = "LandingPageHero-module--button--K8AcE";
export var hero = "LandingPageHero-module--hero--QV1Gj";
export var heroItem = "LandingPageHero-module--heroItem--3kxSe";
export var icon = "LandingPageHero-module--icon--RBLqG";
export var inner = "LandingPageHero-module--inner--5GBbU";
export var navigationList = "LandingPageHero-module--navigationList--vBaQr";
export var navigationListItem = "LandingPageHero-module--navigationListItem--C7xAv";
export var preamble = "LandingPageHero-module--preamble--Vz5kq";
export var shortcutIcon = "LandingPageHero-module--shortcutIcon--ohQML";
export var title = "LandingPageHero-module--title--SrHHL";
export var wrapper = "LandingPageHero-module--wrapper--ImHQB";