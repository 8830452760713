import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { camelCase, upperFirst } from "lodash";
import React, { Fragment } from "react";

import * as components from "./index.js";

export default function SingleTemplate() {
  const pageContext = usePageContext();
  const isFrontPage = pageContext?.isFrontPage;

  const template = isFrontPage
    ? "frontPage"
    : pageContext?.pageAppearance?.template || pageContext.contentType.name;
  const componentName =
    template && upperFirst(camelCase(template)) + "Template";
  const Component =
    // eslint-disable-next-line import/namespace
    (template && components[componentName]) || components["DefaultTemplate"];

  return (
    <Fragment>
      {/* <pre>
        <code>{JSON.stringify({ template, componentName }, null, 2)}</code>
      </pre> */}
      <Component />
    </Fragment>
  );
}
