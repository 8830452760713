/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { H } from "@jfrk/react-heading-levels";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useRef, useState } from "react";

IframeModule.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.any,
  components: PropTypes.objectOf(PropTypes.elementType),
};

export default function IframeModule({
  styles = {},
  title,
  module: { modIframeOptions: { iframeUrl, iframeHeight } = {} },
  ...restProps
}) {
  const iframeRef = useRef(null);
  const [iframeId] = useState(() =>
    Math.random().toString(36).substring(2, 11),
  );
  const [iframeContentHeight, setIframeContentHeight] = useState();
  const iframeOrigin = iframeUrl && new URL(iframeUrl).origin;
  const iframeLoadHandler = () => {
    let iframe = iframeRef.current;
    iframe.contentWindow.postMessage(
      {
        type: "getContentSize",
        iframeId,
      },
      iframeOrigin,
    );
    window.addEventListener("message", (event) => {
      if (event.data?.iframeId === iframeId) {
        let iframeScrollHeight = event.data?.scrollHeight;
        setIframeContentHeight(iframeScrollHeight && iframeScrollHeight);
      }
    });
  };

  return (
    <ModuleWrapper as={"div"} styles={styles} {...restProps}>
      <div className={clsx(styles.innerText)}>
        {title && <H className={clsx(styles.title, "h1")}>{title}</H>}
      </div>
      <iframe
        src={iframeUrl}
        ref={iframeRef}
        width="100%"
        css={css`
          border: none;
          width: 100%;
        `}
        height={iframeContentHeight || iframeHeight}
        onLoad={iframeLoadHandler}
      />
    </ModuleWrapper>
  );
}
