import { H } from "@jfrk/react-heading-levels";
import TextContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/TextContent";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import React from "react";

import usePageContent from "../hooks/usePageContent";

export default function PageHeading({ ...restProps }) {
  const title = usePageContext()?.title;
  const { heading } = usePageContent();

  return <TextContent {...restProps}>{heading || <H>{title}</H>}</TextContent>;
}
