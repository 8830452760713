import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import React from "react";

import ModularityAreaFullWidth from "../../../@municipio/gatsby-theme-basic/components/ModularityAreaFullWidth";

export default function PageSliderAreaModules({ ...restProps }) {
  let { sliderArea } = usePageContext();

  return <ModularityAreaFullWidth area={sliderArea} {...restProps} />;
}
