import ModularityArea from "@municipio/gatsby-theme-basic/src/components/ModularityArea";
import { SkipTo, Icon, Link } from "@whitespace/components";
import HeaderLogo from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderLogo";
import HeaderMainMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderMainMenu";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import LayoutComponent from "../../../components/LayoutComponent";
import { useIsLandingPage } from "../hooks";

import * as defaultStyles from "./Header.module.css";
import HeaderFlyOutMenu from "./HeaderFlyOutMenu/HeaderFlyOutMenu";
import LandingPage from "./LandingPage";

Header.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function Header({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { contentNode: { isFrontPage, sliderArea } = {} } = usePageContext();
  let isLandingPage = useIsLandingPage();

  const { t } = useTranslation();
  return (
    <header className={clsx(styles.component, className)} {...restProps}>
      <SkipTo />
      <div className={styles.header}>
        <LayoutComponent
          className={clsx(styles.headerWrapper, styles.wrapper)}
          type={"fullNoMargin"}
        >
          <HeaderLogo linkTo="/" className={styles.logo} />
          <div>
            <div
              className={clsx(
                styles.helpMenuWrapper,
                isLandingPage && styles.helpMenuLandingPage,
              )}
            >
              <HeaderMainMenu
                aria-label={t("helpMenu")}
                menuName={"helpMenu"}
                className={clsx(styles.helpMenu)}
              />
              <div className={styles.actions}>
                <div className={styles.search}>
                  <Link className={styles.link} to="/sok">
                    <Icon className={styles.icon} name="search" />
                    <span className={styles.label}>{t("search.label")}</span>
                  </Link>
                </div>
                <HeaderFlyOutMenu className={styles.topSectionFlyOut} />
              </div>
            </div>
          </div>
        </LayoutComponent>
        <div className={clsx(styles.headerLowerSectionContainer)}>
          <LayoutComponent
            className={clsx(styles.headerLowerSection)}
            type={"fullNoMargin"}
          >
            <HeaderMainMenu
              aria-label={t("headerTabsMenu")}
              menuName="headerTabsMenu"
              className={styles.tabsMenu}
              isLandingPage={isLandingPage}
            />
            <HeaderFlyOutMenu />
          </LayoutComponent>
        </div>
      </div>

      {/* {isLandingPage && <LandingPage />}
      {sliderArea && <ModularityArea area={sliderArea} sliderArea={true} />} */}
    </header>
  );
}
