// extracted by mini-css-extract-plugin
export var alert = "AlertBanner-module--alert--+asJV";
export var closeButton = "AlertBanner-module--closeButton--1XiLB";
export var closeButtonText = "AlertBanner-module--closeButtonText--H+uHa";
export var component = "AlertBanner-module--component--MH0RE";
export var content = "AlertBanner-module--content--ISKdA";
export var dark = "AlertBanner-module--dark--8jhF5";
export var header = "AlertBanner-module--header--02rjM";
export var icon = "AlertBanner-module--icon--yBfYW";
export var iconClose = "AlertBanner-module--iconClose--JmGNl";
export var title = "AlertBanner-module--title--95bNM";