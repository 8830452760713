import { Link } from "@whitespace/components";
import HTML from "@whitespace/gatsby-theme-wordpress-basic/src/components/HTML";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import LayoutComponent from "../../../components/LayoutComponent";
import FooterLogo from "../../../images/Logo-footer.svg";

import * as defaultStyles from "./Footer.module.css";

Footer.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function Footer({ styles = defaultStyles, ...restProps }) {
  let legalMenu = useMenu("FOOTER_LEGAL");
  if (!legalMenu || !legalMenu.items || legalMenu.items.length === 0) {
    return null;
  }
  const legal = legalMenu.items;

  const content = useStaticQuery(graphql`
    query FooterContent {
      wp {
        acfOptionsFooter {
          footerContent {
            info
            contacts
          }
        }
      }
    }
  `).wp.acfOptionsFooter?.footerContent;

  const { t } = useTranslation();
  return (
    <footer {...restProps} className={styles.footer}>
      <LayoutComponent
        className={clsx(styles.headerWrapper)}
        type={"fullNoSpacing"}
      >
        <div className={styles.wrapper}>
          {!!content && (
            <div className={styles.main}>
              <img className={styles.logo} src={FooterLogo} />
              <div className={styles.content}>
                <div className={styles.contacts}>
                  <h3>{t("contactUs")}</h3>
                  <HTML>{content.contacts}</HTML>
                </div>
                <div className={styles.info}>
                  <h3>{t("info")}</h3>
                  <HTML>{content.info}</HTML>
                </div>
              </div>
            </div>
          )}
          <nav {...restProps}>
            <ul className={styles.legal}>
              {legal.map((item, index) => {
                return (
                  <li key={index} className={styles.legalItem}>
                    <Link to={item.url}>{item.label}</Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </LayoutComponent>
    </footer>
  );
}
