/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleChildPageNav.module.css";
import BoxNavigation from "@whitespace/gatsby-theme-wordpress-basic/src/components/BoxNavigation";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { usePageContext, usePageSiblings } from "../hooks";

PageSiblingNavigation.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function PageSiblingNavigation({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  let {
    contentNode: { id },
  } = usePageContext();
  let pageSiblings = usePageSiblings(id);

  return (
    <BoxNavigation
      className={clsx(styles.component, className)}
      title={t("relatedPages")}
      items={pageSiblings}
      css={css`
        margin-bottom: var(--spacing-lg);
      `}
      {...restProps}
    />
  );
}
