// extracted by mini-css-extract-plugin
export var actions = "Header-module--actions--fLKyV";
export var header = "Header-module--header--3fkf5";
export var headerLowerSection = "Header-module--headerLowerSection--GvGL7";
export var headerLowerSectionContainer = "Header-module--headerLowerSectionContainer--B-xhw";
export var headerWrapper = "Header-module--headerWrapper--INzMY";
export var helpMenu = "Header-module--helpMenu--+nETz";
export var helpMenuWrapper = "Header-module--helpMenuWrapper--Txabc";
export var icon = "Header-module--icon--vzLhl";
export var label = "Header-module--label--G7F0j";
export var logo = "Header-module--logo--gCI6j";
export var search = "Header-module--search--q-ek9";
export var tabsMenu = "Header-module--tabsMenu--BsEvJ";
export var topSectionFlyOut = "Header-module--topSectionFlyOut--oARaR";