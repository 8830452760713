import { FormTextField } from "@whitespace/components";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./FormModuleField.module.css";

FormModuleMessageField.propTypes = {
  field: PropTypes.shape({
    description: PropTypes.string,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
  }),
};

export default function FormModuleMessageField({
  field: { label, description, required },
  ...restProps
}) {
  return (
    <FormTextField
      description={description}
      label={label}
      name={kebabCase(label)}
      required={required}
      className={required && styles.required}
      {...restProps}
    />
  );
}
