import TextContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/TextContent";
import React from "react";

import usePageContent from "../hooks/usePageContent";

export default function PagePreamble({ ...restProps }) {
  const { content } = usePageContent();

  if (!content) {
    return null;
  }

  return <TextContent {...restProps}>{content}</TextContent>;
}
