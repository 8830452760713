import { css } from "@emotion/react";
import { H } from "@jfrk/react-heading-levels";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { Icon, Link, RoundIcon } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./HeroModule.module.css";

HeroModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  icon: PropTypes.object,
  title: PropTypes.any,
  module: PropTypes.any,
  components: PropTypes.objectOf(PropTypes.elementType),
};

export default function HeroModule({
  styles = defaultStyles,
  className,
  title,
  // components: { Icon = RoundIcon } = { Icon: RoundIcon },
  icon = { name: "arrow-right-2" },
  module: { modHeroOptions: { advantages, image, links } = {} },
  ...restProps
}) {
  let linkAttributes = {};
  if (links?.length < 2) {
    linkAttributes = {
      to: links[0]?.link?.url,
      target: links[0]?.link?.target,
    };
  }

  return (
    <ModuleWrapper as={"div"} styles={styles} {...restProps}>
      <div
        className={clsx(styles.content)}
        // eslint-disable-next-line react/no-unknown-property
        css={css`
          width: 100%;
          height: 660px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("${image.src}");
        `}
      >
        <div className={clsx(styles.innerText)}>
          {title && <H className={clsx(styles.title, "h1")}>{title}</H>}
          {!!links && (
            <div className={clsx(styles.links)}>
              {links.map(({ display, link }, index) => {
                if (links.length >= 2) {
                  linkAttributes = {
                    to: link.url,
                    target: link.target,
                  };
                }
                return (
                  <>
                    {display == "button" && (
                      <Link
                        {...linkAttributes}
                        className={clsx(styles.button)}
                        key={index}
                      >
                        {link.title}
                      </Link>
                    )}
                    {display == "link" && (
                      <Link
                        {...linkAttributes}
                        className={clsx(styles.link)}
                        key={index}
                      >
                        {link.title}
                      </Link>
                    )}
                  </>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </ModuleWrapper>
  );
}
