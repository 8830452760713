import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";

export default function useIsFullWidthPage() {
  let pageContext = usePageContext();

  let isFrontPage = pageContext.contentNode?.isFrontPage;
  let contentType = pageContext.contentNode?.contentType?.node?.name;
  let template = pageContext.contentNode?.pageAppearance?.template;

  const isLandingPage = template == "landingPage" && contentType == "page";

  return isFrontPage || isLandingPage;
}
